<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      max-height="420"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
        </v-layout>
    </v-img>
    <v-container  grid-list-md text-center pt-12 style="padding-top:10px !important;">
      <v-container
            fluid
            grid-list-sm
            mb-12
            class="text-left"
          >
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
        <h3 style="font-size: 32px; color: #333; padding-left:20px;">{{this.$t('contact.online.title')}}</h3>
            <h5 style="font-size: 16px; color: #666; padding-left:20px; font-weight: normal; margin:30px 0px;">{{this.$t('contact.online.subTitle')}}</h5>
            <template>
              <v-form
                v-model="messageForm"
                ref="messageForm"
                style="width:100%"
              >
                <v-container
                  fluid
                  grid-list-sm
                  mb-12
                  class="text-left"
                >
                  <v-layout wrap>
                    <v-flex xs12 sm12 md8 lg8>
                      <v-layout wrap>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            outlined
                            name="name"
                            :label="this.$t('contact.online.placeholder.name')"
                            prepend-icon="account_box"
                            :rules="[v => !!v || this.$t('contact.online.valid.name')]"
                            v-model="formData.name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            name="mobile"
                            outlined
                            :label="this.$t('contact.online.placeholder.mobile')"
                            prepend-icon="phone"
                            :rules="[
                              v => !!v || this.$t('contact.online.valid.mobile'),
                              v => /^[1][0-9]{10}$/.test(v) || this.$t('contact.online.valid.mobile')]"
                            v-model="formData.mobile"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            name="email"
                            outlined
                            :label="this.$t('contact.online.placeholder.email')"
                            prepend-icon="email"
                            v-model="formData.email"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            name="title"
                            outlined
                            :label="this.$t('contact.online.placeholder.title')"
                            prepend-icon="title"
                            v-model="formData.title"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12 pr-6>
                          <v-textarea
                            name="content"
                            outlined
                            :label="this.$t('contact.online.placeholder.title')"
                            rows="8"
                            prepend-icon="comment"
                            :rules="[v => !!v || this.$t('contact.online.valid.content')]"
                            v-model="formData.content"
                          ></v-textarea>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12>
                          <v-btn large color="primary" @click="submitForm" style="margin-left:30px;">
                            {{this.$t('contact.online.submit')}}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                        </v-flex>
                      </v-layout>
                </v-container>
              </v-form>
            </template>
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialog" persistent>
                  <v-card>
                    <v-card-text>{{returnMsg}}</v-card-text>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    messageForm: false,
    returnMsg: null,
    dialog: false,
    formData: {
      name: '',
      mobile: '',
      email: '',
      title: '',
      content: ''
    },
    contact: {
      'contact': null,
      'hr': null
    },
    breadcrumbs: []
  }),
  created () {
    document.querySelector('#menu_contact').classList.add('v-btn--active')
    this.getContact()
    this.breadcrumbs = [
      { text: '首页', disabled: false, href: '/' },
      { text: '联系我们', disabled: true },
      { text: '在线留言', disabled: true }
    ]
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getContact () {
      this.https.get('contact').then(response => {
        if (response.code === 0) {
          this.contact = response.data
        }
      })
    },
    submitForm () {
      this.$refs.messageForm.validate()
      if (this.messageForm === true) {
        this.https.get('online_message', this.formData).then(response => {
          if (response.code === 0) {
            this.$refs.messageForm.reset()
          }
          this.returnMsg = response.msg
          this.dialog = true
          let _this = this
          setTimeout(function () {
            _this.dialog = false
          }, 2000)
        })
      }
    }
  }
}
</script>
<style>
</style>
